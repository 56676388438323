import { api } from './api'

export default {
    addWebhook(payload) {
        return api.post('/webhooks', payload.data)
    },

    getWebhook(payload) {
        return api.get(`/webhooks/${payload.id}`)
    },

    testWebhook(payload) {
        return api.post(`webhooks/trigger`, payload.data)
    },

    updateWebhook(payload) {
        return api.put(`/webhooks/${payload.id}`, payload.data)
    },

    deleteWebhook(payload) {
        return api.delete(`/webhooks/${payload.id}`)
    },

    listWebhooks(payload) {
        return api.get(`/webhooks/survey/${payload.id}`)
    }
}

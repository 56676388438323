<template>
    <div class="webhooks-view">
        <ExpandComponent :expandable="hasWebhooks">
            <template #header>
                <RowComponent align="center">
                    <span>What is a webhook?</span>
                </RowComponent>
            </template>
            <p>
                Webhooks are notifications that are triggered automatically when a specific event
                occurs in our platform; for example when your questionnaire receives a new
                submission.
            </p>
            <p>
                By using webhooks you enable SurveyLegend to communicate with your website, web app
                or other system. It may then notify them regarding important events so that they can
                react accordingly.
            </p>
            <RowComponent align="center">
                <ButtonComponent
                    class="button-component--icon"
                    type="primary"
                    shadow
                    @click="handleLearn"
                >
                    <i class="button-component__icon button-component__icon--book" />
                    <span>User guide</span>
                </ButtonComponent>
            </RowComponent>
        </ExpandComponent>
        <CardComponent
            v-for="(webhook, index) in editingWebhooks"
            :key="webhook.id"
            :class="webhook.editing ? 'card-component--webhook-editing' : 'card-component--webhook'"
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <InputComponent v-model="webhook.custom_name" />
                    <TrashComponent @click="handleDelete(index)">Delete</TrashComponent>
                </RowComponent>
            </template>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="endpoint-url"
                        external-link="https://www.surveylegend.com/user-guide/webhooks/#callback-url"
                    />
                    <span>Endpoint URL</span>
                </RowComponent>
                <InputComponent v-model="webhook.url" placeholder="https://" />
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="trigger-on"
                        external-link="https://www.surveylegend.com/user-guide/webhooks/#trigger-condition"
                    />
                    <span>Trigger on</span>
                </RowComponent>
                <RadioGroupComponent v-model="webhook.name">
                    <RadioComponent class="radio-component--success" label="survey.submit"
                        >Submitted</RadioComponent
                    >
                    <RadioComponent class="radio-component--warning" label="survey.start"
                        >Started</RadioComponent
                    >
                </RadioGroupComponent>
            </RowComponent>
            <DividerComponent />
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="data-format"
                        external-link="https://www.surveylegend.com/user-guide/webhooks/#data-format"
                    />
                    <span>Data format</span>
                </RowComponent>
                <RadioGroupComponent v-model="webhook.content_type">
                    <RadioComponent class="radio-component--grape" label="json"
                        >JSON</RadioComponent
                    >
                    <RadioComponent class="radio-component--artichoke" label="xml"
                        >XML</RadioComponent
                    >
                    <RadioComponent label="urlencoded">Web Form</RadioComponent>
                </RadioGroupComponent>
            </RowComponent>
            <RowComponent
                v-if="webhook.name === 'survey.submit'"
                justify="space-between"
                align="center"
            >
                <RowComponent align="center">
                    <HelpComponent
                        markdown="append-responses"
                        external-link="https://www.surveylegend.com/user-guide/webhooks/#attach-responses"
                    />
                    <span>Append responses</span>
                </RowComponent>
                <ToggleComponent v-model="webhook.append_data" />
            </RowComponent>
            <RowComponent justify="space-between" align="center">
                <RowComponent align="center">
                    <HelpComponent
                        markdown="handshake-key"
                        external-link="https://www.surveylegend.com/user-guide/webhooks/#handshake-key"
                    />
                    <span>Handshake key</span>
                </RowComponent>
                <InputComponent
                    v-model="webhook.handshake_key"
                    class="input-component--generate"
                    placeholder="Optional key (A-Z & 0-9)"
                >
                    <template #append>
                        <ButtonComponent
                            class="button-component--zap button-component--icon"
                            shadow
                            @click="handleGenerate(index)"
                        >
                            <i class="button-component__icon button-component__icon--zap" />
                            <span>Generate</span>
                        </ButtonComponent>
                    </template>
                </InputComponent>
            </RowComponent>
            <template #footer>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <ButtonComponent
                            :class="[
                                'button-component--activity',
                                { 'is-testing': webhook.testing }
                            ]"
                            :disabled="isInvalidURL(index)"
                            shadow
                            @click="handleTest(index)"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#fff"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="button-component__icon button-component__icon--activity"
                            >
                                <path d="M22 12h-4l-3 9L9 3l-3 9H2" />
                            </svg>
                            <span v-if="webhook.testing">Testing...</span>
                            <span v-else>Test</span>
                        </ButtonComponent>
                        <div v-if="testError" class="webhooks-view__error">
                            <i
                                class="webhooks-view__error__icon webhooks-view__error__icon--alert-triangle"
                            />
                            <span>{{ testError }}</span>
                        </div>
                    </RowComponent>
                    <RowComponent justify="end" align="center">
                        <div v-if="webhook.last_modified" class="webhooks-view__status">
                            <i
                                class="webhooks-view__status__icon webhooks-view__status__icon--check-circle"
                            />
                            <span>Saved {{ lastSaved(index) }}</span>
                        </div>
                        <ButtonComponent
                            type="success"
                            shadow
                            :disabled="isInvalidURL(index)"
                            @click="handleSave(index)"
                        >
                            <i class="button-component__icon button-component__icon--save" />
                            <span>Save</span>
                        </ButtonComponent>
                    </RowComponent>
                </RowComponent>
            </template>
        </CardComponent>
        <CardComponent
            v-for="(webhook, index) in savedWebhooks"
            :key="webhook.id"
            :class="webhook.editing ? 'card-component--webhook-editing' : 'card-component--webhook'"
        >
            <template #header>
                <RowComponent justify="space-between" align="center">
                    <RowComponent align="center">
                        <span>{{ webhook.custom_name }}</span>
                        <TagComponent v-if="webhook.active" type="success">Enabled</TagComponent>
                        <TagComponent v-else>Disabled</TagComponent>
                    </RowComponent>
                    <RowComponent justify="end" align="center">
                        <ToggleComponent
                            v-model="webhook.active"
                            short
                            prevent
                            @change="handleChange($event, index)"
                        />
                    </RowComponent>
                </RowComponent>
            </template>
            <div class="webhooks-view__flows" @click="handleEdit(index)">
                <div class="webhooks-view__flow">
                    <span>Calls</span>
                    <TagComponent>{{ webhook.url }}</TagComponent>
                </div>
                <div class="webhooks-view__flow">
                    <span>when questionnaire is</span>
                    <TagComponent :class="`tag-component--${triggerClass(index)}`">{{
                        triggerOn(index)
                    }}</TagComponent>
                </div>
                <div class="webhooks-view__flow">
                    <span>with</span>
                    <TagComponent :class="`tag-component--${webhook.content_type}`">{{
                        contentType(index)
                    }}</TagComponent>
                    <span>data format</span>
                    <template v-if="webhook.append_data">
                        <span>and</span>
                        <TagComponent>appends responses</TagComponent>
                    </template>
                </div>
                <div v-if="webhook.handshake_key" class="webhooks-view__flow">
                    <span>signed with</span>
                    <TagComponent>{{ webhook.handshake_key }}</TagComponent>
                </div>
            </div>
        </CardComponent>
        <CardComponent class="card-component--actions">
            <ButtonComponent class="button-component--add" type="success" shadow @click="handleAdd">
                <i class="button-component--add__icon" />
                <span>Add new webhook</span></ButtonComponent
            >
        </CardComponent>
        <Teleport to="body">
            <TestComponent ref="test" />
            <UpgradeComponent
                ref="upgrade-create-webhook-business"
                name="create-webhook-business"
                permission="create-webhook"
                image="create-webhook-legendary.png"
            />
            <UpgradeComponent
                ref="upgrade-create-webhook-legendary"
                name="create-webhook-legendary"
                permission="create-webhook"
                image="create-webhook-legendary.png"
            />
            <WarningComponent
                ref="warning-disable-webhook"
                name="disable-webhook"
                image="close-survey.jpg"
                word="disable"
            />
            <WarningComponent
                ref="warning-delete-webhook"
                name="delete-webhook"
                image="move-survey-to-trash.png"
                word="delete"
            />
        </Teleport>
    </div>
</template>

<script>
import { formatDistance } from 'date-fns'
import AuthService from '@/services/auth-service'
import WebhookService from '@/services/webhook-service'
import { mapGetters } from 'vuex'

export default {
    name: 'Webhooks',

    metaInfo: {
        title: 'Webhooks'
    },

    data() {
        return {
            webhooks: [],
            now: new Date(),
            testError: ''
        }
    },

    computed: {
        ...mapGetters({
            encodedUserId: 'auth/encodedUserId',
            permissions: 'auth/permissions'
        }),

        hasWebhooks() {
            return this.webhooks.length > 0
        },

        hasPermission() {
            return this.permissions['create-webhook']
        },

        editingWebhooks() {
            return this.webhooks.filter((webhook) => webhook.editing)
        },

        savedWebhooks() {
            return this.webhooks.filter((webhook) => !webhook.editing)
        }
    },

    async created() {
        setInterval(() => (this.now = new Date()), 1000 * 60)

        if (this.hasPermission) {
            const { data } = await WebhookService.listWebhooks({
                id: this.$route.params.id
            })

            if (data.length > 0) {
                this.webhooks = data
            }
        }
    },

    methods: {
        isInvalidURL(index) {
            const url = this.webhooks[index].url
            const result = url.match(
                /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
            )

            return result === null
        },

        lastSaved(index) {
            const webhookLastModified = this.webhooks[index].last_modified

            return formatDistance(webhookLastModified, this.now, { addSuffix: true })
        },

        triggerOn(index) {
            let triggerName
            const webhookName = this.webhooks[index].name

            switch (webhookName) {
                case 'survey.submit':
                    triggerName = 'Submitted'
                    break
                case 'survey.start':
                    triggerName = 'Started'
                    break
            }

            return triggerName
        },

        triggerClass(index) {
            let className
            const webhookName = this.webhooks[index].name

            switch (webhookName) {
                case 'survey.submit':
                    className = 'success'
                    break
                case 'survey.start':
                    className = 'warning'
                    break
            }

            return className
        },

        contentType(index) {
            let contentName
            const webhookContent = this.webhooks[index].content_type

            switch (webhookContent) {
                case 'json':
                    contentName = 'JSON'
                    break
                case 'xml':
                    contentName = 'XML'
                    break
                case 'urlencoded':
                    contentName = 'Web Form'
                    break
            }

            return contentName
        },

        handleLearn() {
            window.open('https://www.surveylegend.com/user-guide/webhooks/')
        },

        async handleChange({ value }, index) {
            this.$nextTick(async () => {
                let webhook = this.webhooks[index]

                if (!value) {
                    const isConfirmed = await this.$refs['warning-disable-webhook'].open()

                    this.$refs['warning-disable-webhook'].close()

                    if (!isConfirmed) return
                }

                webhook.active = value

                await WebhookService.updateWebhook({ id: webhook.id, data: webhook })

                const {
                    data: { last_modified }
                } = await WebhookService.getWebhook({
                    id: webhook.id
                })

                this.now = new Date()
                this.webhooks[index].last_modified = last_modified
            })
        },

        handleEdit(index) {
            this.$set(this.webhooks[index], 'editing', true)
        },

        async handleAdd() {
            const permission = 'create-webhook'
            const permissionCount = this.permissions[permission]
            const totalWebhooks = this.webhooks.length

            if (permissionCount !== -1 && totalWebhooks >= permissionCount) {
                const { data: level } = await AuthService.getPermissionLevel({
                    permission,
                    count: permissionCount + 1
                })

                this.track('open-upgrade-modal', permission)

                const isConfirmed = await this.$refs[`upgrade-${permission}-${level}`].open()

                this.$refs[`upgrade-${permission}-${level}`].close()

                if (isConfirmed)
                    window.location.href = `https://www.surveylegend.com/upgrade?source=upgrade_modal&type=${permission}&level=${this.permissions.level}`

                return
            } else if (permission) {
                this.track('has-permission', permission)
            }

            this.webhooks.push({
                survey_id: this.$route.params.id,
                user_id: this.encodedUserId,
                custom_name: `Webhook #${totalWebhooks + 1}`,
                url: 'https://',
                name: 'survey.submit',
                content_type: 'json',
                append_data: false,
                handshake_key: '',
                active: true,
                editing: true
            })
        },

        async handleSave(index) {
            let webhook = this.webhooks[index]

            if (webhook.name !== 'survey.submit') webhook.append_data = false

            this.$delete(webhook, 'editing')

            if (webhook.creation_time) {
                await WebhookService.updateWebhook({ id: webhook.id, data: webhook })

                const {
                    data: { last_modified }
                } = await WebhookService.getWebhook({
                    id: webhook.id
                })

                this.now = new Date()
                this.webhooks[index].last_modified = last_modified
            } else {
                const {
                    data: { id }
                } = await WebhookService.addWebhook({ data: webhook })

                const { data } = await WebhookService.getWebhook({
                    id
                })

                this.now = new Date()
                this.$set(this.webhooks, index, data)
            }
        },

        async handleDelete(index) {
            const webhook = this.webhooks[index]

            if (webhook.creation_time) {
                const isConfirmed = await this.$refs['warning-delete-webhook'].open()

                this.$refs['warning-delete-webhook'].close()

                if (!isConfirmed) return

                WebhookService.deleteWebhook({ id: webhook.id })
            }

            this.$delete(this.webhooks, index)
        },

        handleGenerate(index) {
            let generatedKey = ''

            const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
            const length = characters.length

            // eslint-disable-next-line
            for (let index of Array(10).keys()) {
                generatedKey += characters.charAt(Math.floor(Math.random() * length))
            }

            this.webhooks[index].handshake_key = generatedKey
        },

        async handleTest(index) {
            const webhook = this.webhooks[index]

            this.$set(webhook, 'testing', true)

            try {
                const { data } = await WebhookService.testWebhook({ data: this.webhooks[index] })

                this.$refs['test'].open(data)
            } catch (error) {
                this.testError = 'Timeout exceeded'
            }

            this.$delete(webhook, 'testing')
        },

        track(action, name) {
            this.$gtag.event(action, {
                event_category: name,
                event_label: this.permissions.level
            })
        }
    }
}
</script>

<style lang="scss">
.webhooks-view {
    .trash-component {
        margin-left: 15px;
    }

    .tag-component--json {
        color: #fff;

        background-color: lighten(#6a6aeb, 8);
    }

    .tag-component--xml {
        color: #fff;

        background-color: lighten(#1abc9c, 8);
    }

    .tag-component--urlencoded {
        color: #fff;

        background-color: lighten(#59a8ff, 8);
    }

    p {
        margin-bottom: 20px;
    }

    .card-component--webhook {
        .card-component__header {
            .tag-component {
                margin-left: 10px;
            }
        }

        .card-component__content {
            padding: 0;
        }
    }

    .card-component--webhook-editing {
        .card-component__header {
            padding: 10px 20px;

            .input-component__container {
                background-color: transparent;

                &:focus-within,
                &:hover {
                    background-color: #eaeaea;
                }
            }

            .input-component__input {
                font-size: 15px;
                font-weight: 500;
            }
        }

        .card-component__content > .row-component:not(:last-child) {
            margin-bottom: 20px;
        }

        .card-component__footer {
            padding: 15px 20px;
        }
    }

    .card-component--actions .card-component__content {
        padding: 8px;
    }

    .radio-group-component,
    .radio-component {
        width: 100%;
    }

    .radio-component__inner {
        padding: 13px 18px;
    }

    .radio-component--success {
        &.is-active {
            .radio-component__inner {
                background-color: #2ecc71;
                border-color: #2ecc71 !important;
                box-shadow: -1px 0 0 0 #2ecc71;
            }
        }

        .radio-component__inner {
            &:not(.is-disabled):hover {
                color: #2ecc71;
            }
        }
    }

    .radio-component--warning {
        &.is-active {
            .radio-component__inner {
                background-color: #ffba59;
                border-color: #ffba59 !important;
                box-shadow: -1px 0 0 0 #ffba59;
            }
        }

        .radio-component__inner {
            &:not(.is-disabled):hover {
                color: #ffba59;
            }
        }
    }

    .radio-component--grape {
        &.is-active {
            .radio-component__inner {
                background-color: #6a6aeb;
                border-color: #6a6aeb !important;
                box-shadow: -1px 0 0 0 #6a6aeb;
            }
        }

        .radio-component__inner {
            &:not(.is-disabled):hover {
                color: #6a6aeb;
            }
        }
    }

    .radio-component--artichoke {
        &.is-active {
            .radio-component__inner {
                background-color: #1abc9c;
                border-color: #1abc9c !important;
                box-shadow: -1px 0 0 0 #1abc9c;
            }
        }

        .radio-component__inner {
            &:not(.is-disabled):hover {
                color: #1abc9c;
            }
        }
    }

    .input-component--generate {
        .button-component {
            color: #34495e;

            padding: 12px 16px 12px 14px;

            background-color: #fff;
            border-radius: 4px;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);

            &:hover {
                background-color: #fff;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            }
        }
    }

    .button-component__icon--book {
        @include icon('~@/assets/svg/icons/book.svg');
    }

    .button-component--activity {
        &:not(.is-disabled):hover,
        &.is-testing {
            .button-component__icon--activity {
                animation: stroke 1.4s linear reverse infinite;
            }
        }
    }

    .button-component__icon--activity {
        stroke-dasharray: 60;
        stroke-dashoffset: 120;

        @keyframes stroke {
            to {
                stroke-dashoffset: 0;
            }
        }
    }

    .button-component__icon--save {
        @include icon('~@/assets/svg/icons/save.svg');
    }

    .button-component--zap {
        &:hover .button-component__icon--zap {
            background-color: #ffba59;
        }
    }

    .button-component__icon--zap {
        @include mask-icon('~@/assets/svg/icons/zap.svg');

        background-color: #34495e;

        transition: all 0.2s ease;
    }

    .button-component--add {
        display: flex;

        justify-content: space-between;

        width: 100%;

        padding: 11px 16px;

        border-radius: 4px;

        &:after {
            content: '';

            flex: 1;
        }
    }

    .button-component--add__icon {
        @include icon('~@/assets/svg/icons/plus.svg');

        display: inline-block;

        flex: 1;

        width: 20px;
        height: 20px;

        background-position: left center !important;
        background-size: contain;
    }
}

.webhooks-view__flows {
    display: flex;

    flex-wrap: wrap;

    padding: 20px;

    cursor: pointer;
}

.webhooks-view__flow {
    display: flex;

    align-items: center;

    white-space: nowrap;

    margin: 6px 0;

    span {
        @include font-fira-code;

        max-width: 350px;

        font-size: 12px;
        text-overflow: ellipsis;
        white-space: nowrap;

        margin: 0 4px;

        overflow: hidden;
    }
}

.webhooks-view__error {
    display: flex;

    align-items: center;

    color: #f75851;

    white-space: nowrap;

    margin: 0 15px;
}

.webhooks-view__error__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: -2px 8px 0 0;

    background-size: contain;
}

.webhooks-view__error__icon--alert-triangle {
    @include icon('~@/assets/svg/icons/alert-triangle.svg');
}

.webhooks-view__status {
    display: flex;

    align-items: center;

    color: #65717e;

    white-space: nowrap;

    margin: 0 15px;
}

.webhooks-view__status__icon {
    display: inline-block;

    width: 16px;
    height: 16px;

    margin: -2px 8px 0 0;

    background-size: contain;
}

.webhooks-view__status__icon--check-circle {
    @include icon('~@/assets/svg/icons/check-circle.svg');
}
</style>
